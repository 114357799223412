import React from "react"
import { FaCertificate, FaStar, FaMusic } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Σχετικά" />
      <article className="text-gray-800 leading-normal font-serif">
        <h1 className="font-semibold font-sans text-gray-900 mb-6 text-3xl md:text-4xl leading-tight">
          Τι είναι το υμνολόγιο.gr;
        </h1>
        <p className="my-4">
          Το imnologio.gr είναι μια διαδικτυακή βαση δεδομένων που ευελπιστεί να
          γίνει ένας τόπος-ραντεβού για όσους που ασχολούνται με τη νεότερη
          χριστιανική υμνογραφία. Είναι ένας τόπος, όπου ο κάθε ένας θα μπορεί
          να βρει τις πληροφορίες που θέλει για τον ύμνο που ψάχνει, να
          αποστείλει δικές του δημιουργίες, να προσθέσει τυχόν λεπτομέρειες που
          λείπουν, ακόμα και να αμφισβητήσει τα τεκμήρια των συντακτών.
        </p>
        <p className="my-4">
          Η ιστοσελίδα αυτή λειτουργεί ως εγκυκλοπαίδεια. Θα βρείτε δηλαδή
          στίχους και μουσική διαφόρων ειδών και ποιοτήτων. Το Σωματείο Ελλήνων
          Χριστιανών Καλλιτεχνών δεν ευθύνεται για τυχόν άκομψες ή άτεχνες
          δημιουργίες, καθότι όλες είναι ενυπόγραφες. Είναι υποχρεωμένο να
          εκθέσει οτιδήποτε έχει γραφτεί στην ελληνική και ξένη υμνογραφία και ο
          αναγνώστης θα κρίνει την αξία του.
        </p>
        <p className="my-4">
          Είναι ένας χώρος που λειτουργεί ως βασικός διαδογματικός κόμβος και
          ευχή του ΣΕΧΚ και των συντακτών του είναι να αποτρέψει στο άμεσο
          μέλλον παράλληλες μεταφράσεις ή παραφράσεις ύμνων, που έχουν
          εμφανιστεί και κυκλοφορούν. Ένα από τα προβλήματα που προσπαθεί να
          λύσει η ιστοσελίδα, με πολύ μεγάλη και δύσκολη ερευνητική εργασία
          ομολογουμένως, είναι η δήθεν ταπεινότητα του παρελθόντος, υπεύθυνη για
          την αλλοίωση και καταστροφή του αρχικού δημιουργήματος, εξαιτίας της
          μη αναγραφής των δημιουργών.
        </p>
        <p className="my-4">
          Για την αρτιότερη μορφή και το περιεχόμενο της ιστοσελίδας εργάζονται
          μουσικοί και φιλόλογοι προκειμένου να περιορίσουν στο ελάχιστο τα
          λάθη.
        </p>
        <h2 className="font-semibold font-sans text-gray-900 mb-2 text-xl md:text-2xl">
          Σήμανση ιστοσελίδας
        </h2>
        <p className="my-2">Σχετικά με τη σήμανση της ιστοσελίδας:</p>

        <h3 className="inline-flex items-center text-gray-900 my-1 text-sm md:text-lg">
          <FaCertificate size={14} />
          <span className="ml-2">Επίσημη μετάφραση</span>
        </h3>
        <p className="mt-1 mb-2">
          &laquo;Επίσημη μετάφραση&raquo; σημαίνει πως κάποιος μεταφραστής έχει
          αποσπάσει από την ιδιοκτήτρια εταιρία τα δικαιώματα του ύμνου. Αυτό
          δεν συνεπάγεται κατ’ ανάγκη και αρτιότερο αποτέλεσμα.
        </p>

        <h3 className="inline-flex items-center text-gray-900 my-1 text-sm md:text-lg">
          <FaStar size={14} />
          <span className="ml-2">Προτείνεται από τον ΣΕΧΚ</span>
        </h3>
        <p className="mt-1 mb-2">
          &laquo;Προτείνεται από τον ΣΕΧΚ&raquo; σημαίνει πως η επιτροπή των
          συντακτών υποδεικνύει, κατά τη γνώμη της, κάποια συγκεκριμένη
          μετάφραση.
        </p>

        <h3 className="inline-flex items-center text-gray-900 my-1 text-sm md:text-lg">
          <FaMusic size={14} />
          <span className="ml-2">Διατίθεται παρτιτούρα στα ελληνικά</span>
        </h3>
        <p className="mt-1 mb-2">
          Οι παρτιτούρες των ύμνων αποτελούν την ιδιαίτερη συνεισφορά της
          ιστοσελίδας στον χώρο της υμνογραφίας γιατί πιστεύουμε πως η μουσική
          καταγραφή των ύμνων ήταν, είναι και θα είναι ο σωστός τρόπος αποτροπής
          δυσάρεστων παραφράσεων, διχογνωμιών και μουσικών αντιπαραθέσεων. Οι
          παρτιτούρες έχουν δημιουργηθεί με μελέτη πολλών και διαφορετικών
          εκδόσεων του ίδιου ύμνου και ακροάσεις πληθώρας εκτελέσεων.
        </p>
        <p>Είναι σε μορφή jpg και διαθέτουν:</p>
        <ul className="list-disc list-inside">
          <li className="my-1">τη μελωδία</li>
          <li className="my-1">τις συγχορδίες</li>
          <li className="my-1">
            τους στίχους στα ελληνικά (κάτω από τη μελωδία, αλλά και στο τέλος
            του μουσικού κειμένου στη μεγαλύτερη δυνατή γραμματοσειρά, ούτως
            ώστε να μπορούν να διαβάζονται και από μακριά και από μικρές
            συσκευές τάμπλετ)
          </li>
          <li className="my-1">
            τον τίτλο στα ελληνικά, έτσι όπως εμφανίζεται στον πρώτο στίχο
          </li>
          <li className="my-1">
            τον τίτλο στην πρωτότυπη γλώσσα (όταν ο ύμνος είναι ξένος)
          </li>
          <li className="my-1">τη ρυθμική αγωγή</li>
          <li className="my-1">τον συνθέτη</li>
          <li className="my-1">τον στιχουργό-μεταφραστή</li>
        </ul>

        <h3 className="inline-flex items-center text-gray-900 my-1 text-sm md:text-lg">
          Εξωτερικοί σύνδεσμοι (λινκ)
        </h3>
        <p className="my-2">
          Τα λινκ που συνοδεύουν τους ύμνους είναι από επίσημες ηχογραφήσεις των
          δημιουργών και όχι από διασκευές
        </p>
      </article>
    </Layout>
  )
}

export default AboutPage
